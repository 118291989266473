<template>
  <div id="vm-cola-payment-success" class="vm-cola-payment-success">
    <div class="payment-success-container">
      <h1 class="title">
        Bestie, pick up & <br />
        enjoy your beauties!
      </h1>
      <div class="detail-container">
        <table class="detail">
          <tr>
            <td>
              <div class="label">Nomor Pesanan</div>
              <div class="value">{{ reference_code }}</div>
            </td>
            <td>
              <div class="label">Total Pesanan</div>
              <div class="value">{{ total_price | currencyRp }}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label">Tanggal Pembayaran</div>
              <div class="value">{{ order_detail.created_at | dateFormat }}</div>
            </td>
            <td>
              <div class="label">Metode Pembayaran</div>
              <div class="value">{{ payment_method }}</div>
            </td>
          </tr>
        </table>

        <img src="/static/img/icons/success-payment-detail-icons.svg" />
      </div>
      <!-- <h3>ambil belanjaanmu sekarang!</h3> -->
      <h4>
        Produk tidak keluar dari Vending Machine? <br />
        <b>Scan QR di bawah ini:</b>
      </h4>
      <div class="qr-payment-success">
        <div class="qrcode">
          <template v-if="Object.keys(order_detail).length">
            <qrcode-vue :value="QRcodeValue" :size="230" level="H"></qrcode-vue>
          </template>
        </div>
        <!-- <img src="/static/img/icons/qrcode-customer-service.png" alt="" /> -->
      </div>

      <div class="divider" />

      <div class="user">
        <div v-if="user" class="user__detail">
          <figure v-if="user && user.image">
            <img :src="user.image" :alt="user.name" />
          </figure>
          <span>Masuk sebagai</span>
          <h4 v-if="user && user.name" class="name">{{ user.name }}</h4>
        </div>
        <div class="user__action">
          <button @click.prevent="cancelOrder(true, '/vmcola/home')">
            <span>{{ isLogin ? 'Keluar' : 'Ke Halaman Utama' }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="banners">
      <HomeBanners type="secondary" />
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import { get } from 'lodash';
import helpers from '@/mixins/helpers';
import ls from '@/components/helpers/local-storage';
import autotimeout from '@/mixins/auto-timeout';

const Config = require('~/config/default.env').default;

export default {
  name: 'vmColaPaymentSuccess',
  components: {
    HomeBanners: () => import('@/components/vm-cola/snippets/home-banners/home-banners'),
    QrcodeVue,
  },
  mixins: [helpers, autotimeout],

  beforeRouteLeave(to, from, next) {
    if (from.name == 'vmColaPaymentSuccess') {
      ls.remove('PRODUCT_LIST');
      ls.remove('SLOT_PRODUCT');
    }
    this.cancelOrder(true);
    next();
  },
  data() {
    return {
      order_detail: {},
      QRcodeValue: '',
    };
  },

  computed: {
    store_detail() {
      const store_detail = Cookies.get('store_detail');
      if (store_detail) {
        return JSON.parse(store_detail);
      }

      return null;
    },
    user() {
      return this.$store.state.QRcode.user;
    },
    isLogin() {
      return this.$store.state.QRcode.isLoggedIn;
    },
    reference_code() {
      return this.$route.query.reference_code;
    },
    total_price() {
      return this.$route.query.total;
    },
    payment_method() {
      return this.$route.query.payment_method;
    },
    local_cart() {
      return this.$store.state.vmCola.footer_cart;
    },
    product_list() {
      return this.$store.state.vmCola.product_list;
    },
  },
  async mounted() {
    await this.getOrderDetail();
    await this.dispenseProducts();

    if (!this.$route.query['disable-bucket']) {
      this.getBucket();
    }
  },
  beforeDestroy() {
    this.$store.commit('SET_GLOBAL_LOADING', false);
    this.$store.commit('point_applied', 0);
    this.$store.commit('PRODUCT_LIST', []);
  },
  methods: {
    async getOrderDetail() {
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      const id_order = this.$route.query.id_order;
      if (loc && id_order) {
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const is_guest = await ls.get('is_guest');

        if (is_guest) {
          headers['is-guest'] = true;
          headers['guest-checkout'] = true;
        }

        await axios
          .get(`${Config.MS_ORDER_API_URL}/order/${id_order}`, {
            headers: headers,
          })
          .then((res) => {
            if (res.data.data) {
              this.order_detail = res.data.data;
              const text =
                'https://wa.me/811987881?text=Hi%20saya%20mengalami%20kendala%20untuk%20order%20' +
                this.store_detail?.name +
                '%20dengan%20nomor%20transaksi%20' +
                this.order_detail.reference_code;
              this.QRcodeValue = text;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async getBucket() {
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      if (loc) {
        let products = [];
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }
        if (ls.get('SLOT_PRODUCT')) {
          products = JSON.parse(ls.get('SLOT_PRODUCT'));
        }

        const endpoint = '/bucket';

        await axios
          .post(
            'http://localhost:1945' + endpoint,
            {
              products: products,
            },
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res.status && res.status != 200) {
              // this.$router.push({ path: '/vmcola/order/payment-success-failed' });

              // need to check response from machine
              console.log(res);

              const payload = {
                endpoint,
                status: 'success',
                response: res.data,
              };

              this.setLog(payload);
            }
          })
          .catch((err) => {
            // need to check response from machine
            console.error(err);

            const payload = {
              endpoint,
              status: 'failed',
              response: get(err, 'response', null) || get(err, 'message', '') || 'undefined',
            };

            this.setLog(payload);
          });
      }
    },
    async dispenseProducts() {
      if (this.order_detail) {
        for (let i = 0; i < (this.order_detail.products || []).length; i++) {
          const product = this.order_detail.products[i];
          const comb_id = product.combination.id;

          if (!this.product_list.length) {
            await this.$store.dispatch('getProductList');
          }

          const pr_slot = this.product_list.find((p) => {
            return (p.combinations || []).map((c) => c._id).includes(comb_id);
          });

          for (let j = 0; j < (product.vending_slots || []).length; j++) {
            const vending_slot = product.vending_slots[j];

            for (let k = 0; k < vending_slot.qty; k++) {
              await this.dispenseProduct({
                ...pr_slot,
                combination_id: comb_id,
                vending_slot: product.vending_slots[j].slot,
              });
            }
          }
        }
      }
    },
    async dispenseProduct(product) {
      const payload = {
        product_id: product.id,
        combination_id: product.combination_id,
        store_id: this.order_detail.store.id,
        order_id: this.order_detail._id,
        slot: product.vending_slot,
        user: {
          id: this.order_detail.user.id,
          email: this.order_detail.user.email,
        },
      };

      const endpoint = `/trigger/${product.vending_slot}`;

      return await axios
        .get('http://localhost:1945' + endpoint)
        .then((res) => {
          // need to check response from machine
          console.log(res);

          payload['endpoint'] = endpoint;
          payload['status'] = 'success';
          payload['response'] = res.data;

          this.setLog(payload);
        })
        .catch((err) => {
          // need to check response from machine
          console.error(err);

          payload['endpoint'] = endpoint;
          payload['status'] = 'failed';
          payload['response'] = get(err, 'response', null) || get(err, 'message', '') || 'undefined';

          this.setLog(payload);
        });
    },

    async setLog(payload) {
      try {
        const loc = await Cookies.get('store_location_id');
        const token = await Cookies.get('token');
        if (loc && this.order_detail?._id) {
          const headers = {
            'SOC-PLATFORM': 'vending-machine',
            store_id: loc,
          };

          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }

          await axios.post(`${Config.MS_ORDER_API_URL}/vending-machine/logs`, payload, {
            headers: headers,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'payment-success';
</style>
